import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_t = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - T'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>T</h2>
     <LatQuotesIntro />
     <p><b>Talis qualis</b> - Such as it is.</p>
<p><b>Terra firma</b> - Solid ground.</p>
<p><b>Testamenta latissimam interpretationem habere debent</b> - Testaments ought to have the broadest interpretation.</p>
<p><b>Traditio loqui chartam facit</b> - Delivery makes a deed speak.</p>
<p><b>Transit terra cum onere</b> - The land passes with its burden.</p>

   </Layout>
  )
}

export default LatQuotes_t
